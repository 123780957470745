body[data-layout-size="boxed"] {
  background-color: $boxed-body-bg;

  #layout-wrapper {
    background-color: $body-bg;
    max-width: $boxed-layout-width;
    margin: 0 auto;
    box-shadow: $box-shadow;
  }

  #page-topbar {
    max-width: $boxed-layout-width;
    margin: 0 auto;
  }

  .footer {
    margin: 0 auto;
    max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
  }

  &.vertical-collapsed {
    .footer {
      max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
    }
  }
}

body[data-layout="horizontal"][data-layout-size="boxed"] {
  #page-topbar, #layout-wrapper, .footer {
    max-width: 100%;
  }

  .container-fluid, .navbar-header {
    max-width: $boxed-layout-width;
  }
}

body[data-layout-scrollable="true"] {
  @media (min-width: 992px) {
    #page-topbar, .vertical-menu {
      position: absolute;
    }
  }

  &[data-layout="horizontal"] {
    @media (min-width: 992px) {
      #page-topbar, .topnav {
        position: absolute;
      }
    }
  }
}

