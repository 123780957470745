@import "~@ng-select/ng-select/themes/default.theme.css";

.ng-select.ng-select-single .ng-select-container {
  height: $input-height !important;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #f3f3f9 !important;
  box-shadow: none;
  border: none !important;
  width: auto !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}


.ng-select .ng-select-container {
  border: $input-border-width solid $input-border-color !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.ng-dropdown-panel {
  box-shadow: $box-shadow;
  border: none !important;
  z-index: 99 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: $light !important;
  color: $dark !important;

  &:hover {
    color: $primary !important;
  }
}


.ng-select {
  &.ng-select-focused {
    &:not(.ng-select-opened) > .ng-select-container {
      box-shadow: none !important;
    }
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: #343a40 !important;
  color: $white;

  &:hover {
    color: $white !important;
  }

  .ng-value-icon {
    border: none !important;
  }
}

.ng-select {
  .ng-select-container {
    background-color: $input-bg !important;
    color: $input-color !important;

    input {
      color: $input-color !important;
    }
  }

  .ng-dropdown-panel {
    .ng-option {
      background-color: $input-bg !important;
      color: $input-color !important;
      display: flex !important;
      align-items: center !important;

      &.ng-option-marked {
        color: $primary !important;
      }
    }
  }
}


.ng-select {
  .ng-select-container {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.ng-dropdown-panel-items {
  border: 1px solid #ced4da !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.selectable-filters {
  .ng-select {
    .ng-select-container {
      height: 100% !important;
    }
  }
}

.basic-select {
  .ng-select.ng-select-single .ng-select-container {
    height: $input-height !important;
    background-color: white !important;
    box-shadow: none;
    width: auto !important;
    border: $input-border-width solid $input-border-color !important;
    border-radius: 4px !important;
    padding: 0 !important;

    .ng-dropdown-panel {
      .ng-option {
        background-color: white !important;
        color: $input-color !important;
        display: flex !important;
        align-items: center !important;

        &.ng-option-marked {
          color: $primary !important;
        }
      }
    }
  }

  .is-invalid {
    .ng-select.ng-select-single .ng-select-container {
      border-color: #dc3545 !important;
    }
  }
}
