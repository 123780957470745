//
// _table.scss
//

.table {
  th {
    font-weight: $font-weight-bold;
  }

  .table-light {
    color: $body-color;
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }

  td {
    padding: 0.50rem 0.50rem !important;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}


.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: $table-dark-border-color;
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}


.table > :not(caption) > * > * {
  padding: 0.50rem 0.50rem !important;
}
