
.footer {
  bottom: 0;
  padding: 20px calc(#{$grid-gutter-width} / 2);
  position: absolute;
  right: 0;
  color: $footer-color;
  left: 250px;
  height: $footer-height;
  background-color: $footer-bg;

  @media (max-width: 991.98px) {
    left: 0;
  }

  &-fixed {
    position: fixed !important;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #e6e7ea;
  }
}

.vertical-collapsed {
  .footer {
    left: $sidebar-collapsed-width;

    @media (max-width: 991.98px) {
      left: 0;
    }
  }
}

body[data-layout="horizontal"] {
  .footer {
    left: 0 !important;
  }
}
