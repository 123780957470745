@import "fonts/fonts";
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";
@import "layout/topbar";
@import "layout/bread-crumb";
@import "layout/footer";
@import "layout/sidebar";
@import "layout/layout";
@import "layout/responsive-table";
@import "layout/datepicker";
@import "layout/ng-select";
@import "components/waves";
@import "components/avatar";
@import "components/accordion";
@import "components/helper";
@import "components/preloader";
@import "components/forms";
@import "components/widgets";
@import "components/demos";
@import "components/print";
@import "components/form-wizard";
@import "components/form-upload";
@import "shared-custom";
::ng-deep .nav-pills .nav-link {
  background: #eff2f7;
  font-weight: 600;
}
